@import 'normalize';
@import 'bourbon';
@import 'neat';

// --------------------------------------
//   SITE VARIABLES
// --------------------------------------

// Fonts
$font-header: 'Poppins';
$font-body: 'Rubik';

// Colors
$primary-color: #2a3077;
$primary-color-hover: darken($primary-color, 5%);
$secondary-color: #af2f3b;
$secondary-color-hover: darken($secondary-color, 5%);

$light-gray: #f4f4f4;
$medium-gray: #d9d9d9;
$dark-gray: #4a4a4a;

$white: #ffffff;
$black: #242424;

$text-gray: #7e7e7e;
$link-color: #588bd3;

$header-color: #2e2e2e;

$cta-text: #9c9c9c;

$color-background-light: #f5f5f5;

$color-error: #C63530;
$color-success: #057A2D;

// Distance
$unit: 2rem;

// Shadows
@mixin shadow {
  box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .25);
}

%shadow {
  @include shadow;
}

@mixin shadow-sm {
  box-shadow: 0 .2rem .4rem rgba(0, 0, 0, .2);
}

%shadow-sm {
  @include shadow-sm;
}

@mixin shadow-tiny {
  box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .12);
}

%shadow-tiny {
  @include shadow-tiny;
}

// Border Radiuses
.rounded {
  border-radius: 50%;
}

// Modular Scale
$modular-scale-ratio: 1.25;

// --------------------------------------
//      BODY
// --------------------------------------

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $font-body;
  font-size: 14px;
  color: $text-gray;
  a:link, a:visited, a:hover {
    text-decoration: none;
  }
}

// --------------------------------------
//   RESPONSIVE MIXINS
// --------------------------------------

@mixin sm {
  @media (min-width: 766px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 990px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin sm-and-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-and-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-and-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin xlg-and-up {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin wide-wrap-break {
  @media (max-width: 1362px) {
    @content;
  }
}

// set variable for bourbon neat
//$max-width: 1170px;

// --------------------------------------
//   UTILITY MIXINS
// --------------------------------------

%box-model {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

// Transitions
@mixin transition--medium {
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

%transition--medium {
  @include transition--medium;
}

@mixin transition--fast {
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

%transition--fast {
  @include transition--fast;
}

// No Selection Highlighting
%no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Vertical Align
@mixin vertical-align-center {
  top: 50%;
  transform: translateY(-50%);
}

%vertical-align-center {
  @include vertical-align-center;
}

// bullet
%bulleted {
  display: inline;
  margin-right: .5em;
  position: relative;
  padding-bottom: .5em;
  padding-left: 0;

  &:before {
    content: "•";
    display: inline;
    margin-right: .5em;
    @include sm-and-up {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  @include sm-and-up {
    padding-left: 1.2em;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

// --------------------------------------
//
// SITE-SPECIFIC MIXINS
//
// --------------------------------------

// --------------------------------------
// GRID
// --------------------------------------

// Override Neat's grid settings
$neat-sm-grid: (
  columns: 12,
  gutter: $unit/2
);

$neat-grid: (
  columns: 12,
  gutter: $unit
);

//$column: modular-scale(3) !default; // Column width
//$gutter: modular-scale(3) !default; // Gutter between each two columns

%container {
  @include grid-container();
  margin-left: auto;
  margin-right: auto;

  @include sm {
    max-width: 750px;
  }

  @include md {
    max-width: 970px;
  }

  @include lg-and-up {
    max-width: 1240px;
  }
}

.container {
  @extend %container;
}

.sm-12.column {
  @include grid-column(12);
}

.sm-6.column {
  @include grid-column(6);
}

@include md-and-up {

  .md-1.column {
    @include grid-column(1);
  }

  .md-2.column {
    @include grid-column(2);
  }

  .md-3.column {
    @include grid-column(3);
  }

  .md-4.column {
    @include grid-column(4);
  }

  .md-6.column {
    @include grid-column(6);
  }

  .md-8.column {
    @include grid-column(8);
  }

  .md-12.column {
    @include grid-column(12);
  }

}

// --------------------------------------
// PANELS
// --------------------------------------
.panel {
  background-color: $light-gray;
  padding: $unit 0;
  margin-bottom: $unit;
  &.collapse {
    margin-bottom: 0;
  }
  > .content-block {
    padding-bottom: 0;
  }
}

.panel__dark {
  background-color: $black;
  color: $white;
  h2 {
    text-transform: uppercase;
    text-align: center;
    color: $white;
  }
}

.panel--cta {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  h2, h3, h4 {
    color: $cta-text;
    font-weight: 300;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

// --------------------------------------
// BUTTONS
// --------------------------------------

%button {
  @extend %transition--medium;
  display: inline-block;
  text-align: center;
  padding: .8em 2.25em;
  cursor: pointer;
  font: bold 1em $font-body;
  border: 0;
  margin-bottom: 1rem;
}

%button--primary {
  background: $primary-color;
  color: $white;
  border-bottom: 3px solid darken($primary-color, 10%);

  &:hover {
    background: darken($primary-color, 5%);
    color: $white;
  }
}

%button--secondary {
  background: $secondary-color;
  color: $white;
  border-bottom: 3px solid darken($secondary-color, 10%);

  &:hover {
    background: darken($secondary-color, 5%);
    color: $white;
  }
}

%button--simple {
  background: $light-gray;
  color: $primary-color;
  border-bottom: 3px solid darken($light-gray, 10%);

  &:hover {
    background: darken($light-gray, 5%);
    color: $primary-color;
  }
}

.button-array {
  margin-top: $unit/2;
  margin-bottom: $unit/2;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  &__button {
    @extend %button;
    margin-right: 1rem;
    @extend %button--primary;
  }
  &__button--secondary {
    @extend %button;
    margin-right: 1rem;
    @extend %button--secondary;
  }

  &__button--simple {
    @extend %button;
    margin-right: 1rem;
    @extend %button--simple;
  }
}

.button__hollow {
  @extend %button;
  border: solid 1px $medium-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: $black;

  &.white {
    background-color: $white;
  }

  svg, span {
    flex-shrink: 1;
  }

  svg {
    fill: $black;
    width: 1.5rem;
    height: 1.5rem;
  }

  .text {
    padding: 1rem;
    text-align: center;
  }
}

// --------------------------------------
// SOCIAL LINKS
// --------------------------------------
.social-links {
  margin: 0;
  li {
    display: inline-block;
    font-size: modular-scale(2);

    a {
      .icon {
        @extend %transition--medium;
        display: inline-block;
        fill: $primary-color;
        width: 1.25em;
        height: 1.25em;
      }
      &:hover {
        .icon {
          fill: darken($primary-color-hover, 7%);
        }
      }
    }
  }
  &--light {
    li {
      a {
        .icon {
          fill: rgba(255, 255, 255, .65);
        }
        &:hover {
          .icon {
            fill: white;
          }
        }
      }
    }
  }
}

// --------------------------------------
// HEADER
// --------------------------------------
$sub-nav-width: 220px;
.header {
  position: relative;
  padding: $unit/2 0;
  font-size: modular-scale(-1);
  @include sm {
    margin: 0 $unit/2 $unit/2;
  }
  @include md-and-up {
    margin: 0;
    padding: 0;
  }

  &__secondary {
    @include grid-container();
    padding-bottom: $unit/2;
    @include md-and-up {
      background: $light-gray;
      padding-top: $unit/2;
    }

    .nav__left, .nav__right {
      @include grid-column(12, $neat-sm-grid);
      @include md-and-up {
        @include grid-column(6, $neat-grid);
      }
    }

    .nav__left {
      display: none;
      @include md-and-up {
        display: block;
      }
    }

    .nav__right {
      text-align: center;
      @include md-and-up {
        text-align: right;
        position: relative;
        top: 5px;
      }
      .header__login {
        font-size: modular-scale(1);
        margin-right: $unit/2;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .social-links {
    display: none;
    @include md-and-up {
      display: block;
    }
    li {
      margin-bottom: 0;
    }
  }

  &__login {
    font: 500 1em $font-body;
    display: inline-block;
    color: $primary-color;
    @include transition--medium;

    &:hover {
      color: $primary-color-hover;
    }
  }

  &__menu-toggle {
    display: block;
    margin-top: $unit/3;
    margin-bottom: $unit/3;
    text-align: center;
    @include md-and-up {
      display: none;
    }
    .icon {
      width: 1rem;
      height: 1rem;
      fill: $primary-color;
      position: relative;
      top: .1rem;
    }
    a {
      color: $primary-color;
      text-transform: uppercase;
      font: bold 1rem $font-header;
      &:hover {
        color: $primary-color;
      }
    }
  }

  &__logo {
    display: block;
    text-align: center;
    @include md-and-up {
      width: 200px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 25px;
    }
    a {
      display: inline-block;
    }
  }

  &__nav {
    display: none;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 999;
    background: $white;
    @include shadow-tiny;
    @include md-and-up {
      display: block;
      position: relative;
      text-align: center;
      margin-top: 6rem;
      padding-top: .5rem;
      box-shadow: none;
      @include grid-column(12);
    }
    .nav {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        @include md-and-up {
          display: flex;
        }
      }
      &__item {
        margin-bottom: 0;
        @include md-and-up {
          flex-grow: 1;
        }

        a {
          display: block;
          color: $primary-color;
          font-weight: 400;
          text-transform: uppercase;
          @include transition--fast;
          padding: $unit/4 $unit/2;
          border-top: 1px solid $light-gray;
          @include md-and-up {
            padding-top: 0;
            border-top: none;
            color: $dark-gray;
            line-height: 1.6;
            font-size: 15px;
          }

          @include lg-and-up {
            padding-left: $unit/1.75;
            padding-right: $unit/1.75;
          }
        }

        &--has-children {
          position: relative;
          ul {
            margin: 0;
            padding: 0;
            @include md-and-up {
              display: none;
              background: white;
              position: absolute;
              text-align: left;
              z-index: 90;
              width: $sub-nav-width;
              border: 1px solid $medium-gray;
              @include shadow;
            }
            li {
              border-bottom: 1px solid $light-gray;
              &:last-child {
                border: 0;
              }
              display: block;

              a {
                display: block;
                text-transform: none;
                font-weight: 300;
                &:hover {
                  background: $color-background-light;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
          ul.nav__item__child-nav a {
            padding: $unit/4 $unit/1.75 $unit/4 $unit;
            @include md-and-up {
              padding: $unit/4 $unit/1.75;
            }
          }
          ul.nav__item__grandchild-nav {
            display: none;
            a {
              padding: $unit/4 $unit/1.75 $unit/4 $unit*2;
              @include md-and-up {
                padding: $unit/4 $unit/1.75;
              }
            }
          }
          &:hover {
            > ul.nav__item__child-nav {
              display: block;
            }
          }
        }


      }
    }
  }

  .nav > ul > .nav__item > a {
    @include md-and-up {
      margin-bottom: .5rem;
      border-left: solid 1px $medium-gray;
      &:last-child {
        border-right: solid 1px $medium-gray;
      }
    }
  }

  .nav > ul > .nav__item--has-children {
    @include md-and-up {
      > a:after {
        content: "▾";
        display: block;
        color: $medium-gray;
        bottom: 0px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .nav__item--has-children > .nav__item__child-nav > .nav__item--has-children > ul {
    left: calc(#{$sub-nav-width} - 3px);
    top: 0;
  }

}

.header__nav.sticky {
  position: fixed;
  top: -6rem;
  left: 0;
  margin-left: 0;
  width: 100%;
  @include shadow-tiny;
  > .nav {
    @extend .container;
    float: none;
    width: auto;
    > ul {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
}

// --------------------------------------
// FOOTER
// --------------------------------------

.footer {
  padding: $unit 0;
  background: $black;
  color: $dark-gray;
  &__nav {
    @include grid-column(12, $neat-sm-grid);
    text-align: center;
    padding-top: $unit;
    padding-bottom: $unit*.75;
    @include md-and-up {
      @include grid-column(12, $neat-grid);
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
    }
    .nav__item {
      display: inline-block;
      text-align: center;
      flex-grow: 1;

      &:last-child {
        padding-right: 0;
      }
      a {
        color: white;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 300;
        font-size: modular-scale(-1);
        padding: $unit/4;
      }
    }
  }

  &__top {
    @include grid-column(12, $neat-sm-grid);
    display: flex;
    align-items: center;
    border-bottom: solid 1px $dark-gray;
    padding-bottom: 1.5rem;
    @include md-and-up {
      @include grid-column(12, $neat-grid);
    }
  }

  &__logo {
    display: none;
    @include md-and-up {
      display: block;
      @include grid-column(2, $neat-grid);
      margin-left: 0;
    }
  }

  &__social {
    @include grid-column(12, $neat-sm-grid);
    text-align: center;
    @include md-and-up {
      @include grid-column(6, $neat-grid);
      flex: 1 1 auto;
      text-align: right;
      float: right;
    }
    li {
      font-size: modular-scale(2);
      &:last-child {
        margin-right: 0;
      }
      a .icon {
        fill: $dark-gray;
        &:hover {
          fill: $dark-gray;
        }
      }
    }
  }

  &__bottom {
    .credit, .legal {
      @include grid-column(12, $neat-sm-grid);
      text-align: center;
      color: $dark-gray;
      font-size: modular-scale(-1);
      padding-bottom: 1rem;
      a {
        color: $dark-gray;
      }
    }

    @include md-and-up {
      .credit {
        @include grid-column(4, $neat-grid);
        text-align: right;
      }
      .legal {
        @include grid-column(8, $neat-grid);
        text-align: left;
      }
    }
  }
}

.social-links {
  padding: 0;
  list-style-type: none;
  li {
    margin-right: $unit/2;
  }
}

// --------------------------------------
// HERO
// --------------------------------------

.hero.slick-slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: $unit 0;
}

.hero {
  &__content {
    @include grid-column(12, $neat-sm-grid);
    @include md-and-up {
      @include grid-column(9, $neat-grid);
    }
  }

  &__header {
    color: white;
    margin-bottom: 0;
    font-size: modular-scale(4);
    font-weight: 500;
    text-align: center;
    text-shadow: 2px 2px 2px $black;
    @include md-and-up {
      font-size: modular-scale(6);
      text-align: left;
    }

    span {
      display: block;
      font-size: modular-scale(-3);
      font-weight: 300;
    }
  }

  .button-array {
    text-align: center;
    font-size: modular-scale(1);
    margin-top: 0;
    @include md-and-up {
      text-align: left;
    }
  }

  &__404 {
    text-align: center;
    padding: $unit*1.5 0;
    color: white;
    &__header {
      font: bold modular-scale(4) $font-header;
      font-size: modular-scale(1);
      @include md-and-up {
        font-size: modular-scale(0);
      }
    }
  }
}

.hero.video {
  max-height: 500px;
  overflow: hidden;
  > video {
    @include md-and-up {
      top: 25%;
      transform: translateY(-25%);
    }
  }
}

// --------------------------------------
// HEADLINE
// --------------------------------------

.headline {
  background: $primary-color;
  padding: 2rem 0;
  margin-bottom: 1.5rem;
  h1, h2, h3, h4 {
    color: $white;
    padding: 0;
    font-weight: 500;
    @include grid-column(12);
  }
  h1, h2 {
    text-transform: uppercase;
  }
  .breadcrumb {
    font-weight: lighter;
    margin-bottom: .25rem;
    > a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
}

// --------------------------------------
// MEDIA NAV ITEM
// --------------------------------------
.media-nav-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  @include md-and-up {
    height: 350px;
  }
  h2 {
    color: $white;
    font-size: modular-scale(3);
    font-weight: normal;
    margin-bottom: 1.25rem;
  }
  .button {
    margin-bottom: 0;
  }
}

// --------------------------------------
// MEDIA ASPECT RATIO
// --------------------------------------
.media-aspect {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  iframe {
    position: absolute;
    width: 100% !important;
    height: 100%;
    left: 0; top: 0;
  }
}

// --------------------------------------
// MEDIA IMAGE OVERLAY
// --------------------------------------
.media-image {
  display: block;
  position: relative;
  img {
    display: block;
  }
  .button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .button-container__button {
      @extend %button;
      @extend %button--secondary;
      text-align: center;
    }
  }
}

// --------------------------------------
// TYPOGRAPHY
// --------------------------------------

h1, h2, h3, h4, h5 {
  color: $header-color;
  margin: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  &.light {
    color: $text-gray;
    font-weight: 300;
  }
}

h1 {
  font-size: modular-scale(3);
}

h2 {
  font-size: modular-scale(2);
  &.larger {
    font-size: modular-scale(2.5);
  }
}

h3 {
  font-size: 1.5em;
  font-weight: 500;
  color: #9b9b9b;
}

h4 {
  font-size: 16px;
  font-weight: normal;
}

p {
  margin: 0 0 1.25rem;
  line-height: 1.4;
  font-weight: 300;
}

ol, ul {
  margin: 0 0 1.25rem;
  li {
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: .625rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a {
  color: $link-color;
  &:hover {
    color: darken($link-color, 5%);
  }
}

hr {
  @extend %container;
  width: percentage(7/12);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: .5rem;
}

img {
  width: 100%;
  height: auto;
}

.text-upper {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-right-md {
  @include md-and-up {
    text-align: right;
  }
}


// --------------------------------------
// CONTENT BLOCK & TEXT CONTENT
// --------------------------------------

.content-block {
  @extend %container;
  padding-bottom: $unit;

  &--narrow {
    @include md-and-up {
      padding-left: 75px;
      padding-right: 75px;
    }
    @include lg-and-up {
      padding-left: 150px;
      padding-right: 150px;
    }
  }
  &--xtra-narrow {
    @include md-and-up {
      padding-left: 125px;
      padding-right: 125px;
    }
    @include lg-and-up {
      padding-left: 250px;
      padding-right: 250px;
    }
  }

  &.push-top {
    padding-top: 2.25rem;
  }

  &.collapse {
    padding-bottom: 0;
  }

  h2, h3, h4 {
    padding-top: 0;
    padding-bottom: .75rem;
  }

  .section-title {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .content > p:last-child {
    margin-bottom: 0;
  }
}

.text-content {
  @include grid-column(12);

  .media {
    width: 100%;
    float: left;
    margin-bottom: map_get($neat-sm-grid, gutter);
    flex: 1 0 auto;
    @include md-and-up {
      width: calc(50% - #{map_get($neat-grid, gutter)});
      margin-right: map_get($neat-grid, gutter);
    }

    &.right {
      float: right;
      margin-left: 0;
      margin-right: 0;
      @include md-and-up {
        margin-left: map_get($neat-grid, gutter);
      }
    }

    &.video {
      width: 100%;
      @include md-and-up {
        width: calc(50% - #{map_get($neat-grid, gutter)});
      }
    }

    &.shrink {
      @include md-and-up {
        width: calc(33% - #{map_get($neat-grid, gutter)});
      }
    }

    > .video-wrap {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
      iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
  .content {
    width: 100%;
    float: left;
    @include md-and-up {
      width: auto;
      float: none;
    }
  }
}

.text-content__flex {
  @extend .text-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include md-and-up {
    flex-wrap: nowrap;
    .media {
      margin-bottom: 0;
      &.right {
        order: 2;
      }
    }
  }

  > .content {
    flex: 1 1 auto;
  }
}

.column-content {
  @include grid-column(12);
  @include md-and-up {
    @include grid-column(6);
    &:nth-child(odd) {
      clear: left;
    }
  }
}

.content {
  .nested-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-row {
    display: flex;
    align-items: flex-start;
    padding-bottom: $unit/2;

    .img {
      flex: 1 1 15%;
      img {
        max-width: 100%;
      }
    }

    .text {
      flex: 1 0 85%;
      padding-left: $unit/2;
    }

  }

  .flex-content {
    display: flex;
    align-items: flex-start;
    > * {
      flex: 1 1 0;
    }
  }

  hr {
    margin: $unit auto;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery-item {
  @include grid-column(12);
  padding-bottom: 1.5rem;
  @include md-and-up {
    @include grid-column(3);
  }
  img {
    display: block;
    margin: 0 auto 1rem;
  }
  .gallery-text {
    text-align: center;
  }
}

.site-links {
  float: left;
  width: 100%;
  margin: $unit 0;
  .link-image {
    float: left;
    width: percentage(1/5);
    padding-right: $unit/2;
    img { width: 100%;}
  }
  .link-content {
    float: left;
    width: percentage(4/5);
    h4 {
      font-size: modular-scale(1);
    }
  }
}

// --------------------------------------
// LISTS
// --------------------------------------

.link-list-box {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: solid 1px $medium-gray;
  background-color: $white;
  li {
    position: relative;
    padding: .5rem 1.75rem .5rem .5rem;
    border-bottom: solid 1px $medium-gray;

    a { display: block; }

    .date {
      display: block;
      font-size: modular-scale(-2);
      font-weight: bold;
      color: $link-color;
      padding-bottom: .5rem;
    }

    .title {
      display: block;
      font-size: modular-scale(0);
      color: $black;
    }

    svg.icon--slider-arrow {
      fill: $medium-gray;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.list-box {
  .list-heading-row {
    display: flex;
    align-items: center;
    color: $black;
    font-weight: 500;
  }
  .list-row {
    display: flex;
    align-items: center;
    border-top: solid 1px $medium-gray;
    border-left: solid 1px $medium-gray;
    border-right: solid 1px $medium-gray;
    clear: both;
    font-weight: 300;
    &:last-child {
      border-bottom: solid 1px $medium-gray;
    }
    &:nth-child(even) {
      background-color: $light-gray;
    }
  }

  .list-column {
    flex: 1 0 0;
    padding: 1.25rem 0 1.25rem 1rem;
    &:last-child {
      padding-right: 1rem;
    }
    > h4 {
      padding-bottom: 0;
    }
  }
}

.list-plain {
  .list-row {
    display: flex;
    align-items: center;
    clear: both;
  }

  .list-column {
    flex: 1 0 0;
    padding: 1.25rem 1rem 1.25rem 0;
    &:last-child {
      padding-right: 0;
    }
  }
}

.event-list {
  .list-heading-row {
    display: none;
    @include sm-and-up() {
      display: flex;
    }
  }
  .list-row {
    flex-wrap: wrap;
    @include sm-and-up() {
      flex-wrap: nowrap;
    }
  }
  .list-column {
    flex: 0 0 50%;
    @include sm-and-up() {
      flex-basis: 13%;
      word-break: break-word;
    }
    &.date {
      flex-basis: 40%;
      @include sm-and-up() {
        flex-basis: 13%;
      }
    }
    &.event {
      flex: 1 1 60%;
      padding-right: 1rem;
      @include sm-and-up() {
        flex: 0 0 28%;
        padding-right: 0;
      }
    }
    &.location {
      flex: 1 0 50%;
      @include sm-and-up() {
        flex: 0 0 20%;
      }
    }
    &.city {
      flex: 0 1 0;
      padding-right: 1rem;
      @include sm-and-up() {
        flex: 0 0 13%;
        padding-right: 0;
      }
    }
  }
}

.calendar-filters {
  padding-top: 1rem;
  select {
    margin-bottom: .5rem;
  }
  @include md-and-up() {
    .column:not(:first-child) {
      margin-left: 1.25rem;
    }
  }
}

.event-details {
  display: flex;
  &.constrain {
    @include md-and-up {
      max-width: 50%;
    }
  }
  h4 {
    color: $text-gray;
    padding-bottom: 0;
  }
  span {
    font-weight: 300;
  }

  .event-detail {
    padding-bottom: $unit/2;
    padding-right: $unit/2;
  }
  .event-detail__sm {
    flex: 0 1 20%;
  }

  .event-detail__md {
    flex: 0 1 30%;
  }

  .event-detail__lg {
    flex: 1 1 50%;
  }

}

.event-nav {
  border-top: solid 1px $medium-gray;
  padding-top: 2rem;
  a {
    font-weight: 400;
    color: $black;
    padding-right: 2.5rem;
    &.active {
      color: $primary-color;
      font-weight: 500;
    }
  }
}

// Section Header
.section-header {
  text-align: center;
  position: relative;
  margin-top: $unit;
  margin-bottom: $unit;

  &__header {
    font: bold modular-scale(2) $font-header;
    text-transform: uppercase;
  }

}

// --------------------------------------
// FORM: GENERAL
// --------------------------------------

label {
  font: 14px $font-body;
  display: block;
  margin-bottom: .25em;
  &.inline {
    display: inline-block;
  }
  &.error {
    color: $color-error;
    font-size: .8rem;
    padding-top: .25rem;
  }
}

input[type='text'], input[type='email'], input[type='password'], input[type='tel'], textarea, select {
  width: 100%;
  background: $white;
  border: solid 1px $medium-gray;
  padding: $unit/2 $unit/2;
  margin: 0 0 $unit/2;
  font: 14px $font-body;
  &.inline {
    width: auto;
  }
  &.error {
    display: inline-block;
    border-color: $color-error;
  }
}

input[type=submit] {
  @extend %button;
  @extend %button--secondary;
  margin-bottom: 1.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

textarea {
  height: 10em;
}

select {
  height: 3.25rem;
  border-radius: 0;
  &.inline {
    width: 50%;
  }
}

input[type='radio'], input[type='checkbox'] {
  width: auto;
  margin: 0 .5rem;
}

.login-form {
  background: url('/assets/img/igor-shot.jpg') center 65% no-repeat;
  background-size: cover;
  padding: $unit*4 0;
  > .content-block {
    padding-bottom: 0;
  }
}

.form-container {
  @include grid-container();
  background-color: $light-gray;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);

  fieldset {
    padding: 1.5rem 0;
    margin: 0;
    border: none;
    border-bottom: solid 1px $medium-gray;
    &:last-child {
      border-bottom: none;
    }
  }

  input[type='submit'] {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .column.inline {
    text-align: right;
    label {
      display: inline-block;
    }
    input, select {
      margin-left: .5rem;
      width: 40%;
    }
  }

  h2, h3, h4 {
    font-weight: normal;
    padding-bottom: 1.25rem;
  }

  .heading {
    padding-bottom: .25rem;
  }

  > h2, > h3, > h4 {
    @include grid-column(12);
  }

  .button-array {
    margin-top: 0;
  }

  .login-help {
    @include grid-container();
    @include md-and-up {
      font-size: modular-scale(-1);
    }
    .column {
      text-align: center;
      @include md-and-up {
        text-align: left;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .flex-row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    clear: both;
    @include md-and-up {
      flex-wrap: nowrap;
    }
    .column {
      padding-bottom: 1rem;
      * {
        margin-bottom: 0;
      }
    }
  }

  .field-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: solid 1px $medium-gray;
    input {
      border: none;
    }
  }

  .field-group__phone {
    @extend .field-group;
    background-color: $white;
    text-align: center;
    span {
      padding: 0 .25rem;
      font-size: 1.5rem;
      flex: 0 1 auto;
    }
  }

}

.form-container .dform {
  @extend fieldset;
  @include grid-column(12);

  .dform_element {
    margin-bottom: 1rem;
    input {
      margin-bottom: 0;
    }
  }

  .dfcolumns {
    .column {
      float: left;
    }
    .column .dform_container {
      width: calc(100% - 2rem);
      margin: 0 2rem 0 0;
    }
    .column-last .dform_container {
      width: 100%;
      margin-right: 0;
    }
  }

  .dfinput_left, .dfinput_right {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    label {
      font-weight: 300;
      font-size: modular-scale(0);
    }
  }

  .dform_desc {
    width: 100%;
    float: left;
    font-weight: 300;
    font-size: modular-scale(0);
  }
}

#form-errors {
  display: none;
  padding-top: $unit;
}

#otherClub {
  display: none;
}

.handicap_result {
  font-weight: 500;
}


// --------------------------------------
// HALL OF FAME
// --------------------------------------
.member-grid {
  text-align: center;
  clear: both;
  float: left;
  width: 100%;
  .grid-item {
    display: inline-block;
    width: calc(100% - 3rem);
    @include md-and-up {
      width: calc(50% - 3rem);
    }
  }
  img {
    padding: $unit/2;
  }
}

.induction-galleries {
  @include grid-column(12);
  padding-bottom: $unit;
  text-align: center;
  @include md-and-up {
    @include grid-column(3);
  }
  img {
    padding-bottom: $unit;
  }
}

.slim-gallery {
  width: calc(100% - .5rem);
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  @include md-and-up {
    $width: percentage(2.4/12);
    width: calc(#{$width} - .5rem);
  }
}


// --------------------------------------
// ALERT
// --------------------------------------
.alert {
  padding: $unit/2;
  margin: 0 0 $unit/2;
  text-align: center;
  color: white;

  &__header {
    font: 500 modular-scale(2) $font-body;
  }
  &__image {
    svg {
      width: 3em;
      height: 3em;
      fill: white;
    }
    margin-bottom: $unit/4;
  }
  &__message {
    margin: 0;
  }
  &__items {
    margin-top: $unit/2;
  }
  &.alert--danger {
    background: $color-error;
  }
  &.alert--success {
    background: $color-success;
  }
}

.alert-text {
  color: $color-error;
}


// --------------------------------------
// CALL TO ACTION
// --------------------------------------
.cta {
  background: $color-background-light;
  padding: $unit*1.5 0;

  &--photo {
    background: url(/assets/img/cta_area.jpg) center center no-repeat;
    background-size: cover;
    > .content-block {
      padding-bottom: 0;
    }
  }

  &__text {
    background: rgba(255, 255, 255, 1);
    padding: $unit;
    text-align: center;
    @include shadow-sm;
  }

  h2 {
    font-size: modular-scale(2);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3;
  }
}


// --------------------------------------
// ARTICLES
// --------------------------------------
.listing {
  h3 {
    padding-bottom: 0;
  }
  .pub-date {
    font-size: modular-scale(-1);
    padding-bottom: .75rem;
  }
  article {
    border-bottom: solid 1px $medium-gray;
    margin-bottom: 1rem;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}


// --------------------------------------
// MEMBERSHIP
// --------------------------------------

#enterGhin {
  display: none;
  padding-top: 1rem;
  input {
    margin-bottom: 0;
  }
}

#paymentErrors .alert {
  margin: 0;
  color: $color-error;
}


// --------------------------------------
// WIDGETS
// --------------------------------------
.widget-center {
  margin: $unit auto;
}


// --------------------------------------
// IMPORTS
// --------------------------------------
@import "slider";
@import "loader";
@import "lightbox";